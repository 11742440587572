<template>
	<SectionHeader v-if="band && band.name" :image="band.post.picture" title="Band" :page="band.name" />
</template>
<script>
import SectionHeader from '@/views/SectionHeader.vue'
export default {
	name: "Band",
	components: {
		SectionHeader
	},
	props: ["id"],
	methods: {
	},
	computed: {
		band() {
			return this.$store.state.bands.find(e => e.id == this.id);
		},
		loading() {
			return this.$store.state.loadingStatus;
		}
	},
	created() {
		this.$store.dispatch('loadBands');
	},
};
</script>